import React, { useEffect, useState } from "react";

import onlineIcon from "../../icons/onlineIcon.png";
import closeIcon from "../../icons/closeIcon.png";

import "./InfoBar.css";
import { useLocation } from "react-router-dom";

const InfoBar = ({
  room,
  userLanguage,
  userName,
  setUserName,
  setExitModal,
  nickNameModal,
}) => {
  const [adminName, setAdminName] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [userLocation, setUserLocation] = useState("");
  const location = useLocation();

  useEffect(() => {
    const adminName = new URLSearchParams(location.state);
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get("room") === null) {
      setIsAdmin(false);
      const userInfo = JSON.parse(localStorage.getItem("userInfo"));
      setUserName(userInfo?.userName);
      setUserLocation(searchParams.get("location"));
    } else {
      setAdminName(adminName.get("data"));
      setIsAdmin(true);
    }
  }, [userName]);

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));

    if (isAdmin && adminName === "") {
    }
  }, [isAdmin]);

  return (
    <div className="infoBar">
      <div className="leftInnerContainer">
        <div>language:{userLanguage}</div>
        <div> kiosk location: {userLocation}</div>
        <div>
          {isAdmin
            ? `  manager :    ${
                adminName === "" || adminName === null ? "미배정" : adminName
              }`
            : `${userName !== undefined ? `userName : ${userName}` : ""}`}
        </div>
      </div>
      <div
        onClick={() => {
          setExitModal(true);
        }}
        style={{
          padding: 15,
        }}
        className="rightInnerContainer"
      >
        {!nickNameModal && <img src={closeIcon} alt="close icon" />}
      </div>
    </div>
  );
};

export default InfoBar;
