import React, { useEffect, useState } from "react";

import "./Message.css";

import ReactEmoji from "react-emoji";
import { useLocation } from "react-router-dom";

const Message = ({ message: { text, user, timestamp }, userLanguage }) => {
  const location = useLocation();
  const [name, setUserName] = useState("");
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const room = searchParams.get("room");
    if (room === null) {
      setUserName("user");
    } else {
      setUserName("admin");
    }
  }, []);
  function showText() {
    if (user === "admin") {
      if (name !== "admin") {
        return (
          <div className="messageContainer justifyStart">
            <div className="messageBox backgroundLight">
              <p className="messageText colorDark">
                {text.transLateLanguage !== ""
                  ? ReactEmoji.emojify(text.transLateLanguage)
                  : ReactEmoji.emojify(text.originMessage)}
              </p>
            </div>
            <div
              style={{
                marginTop: 10,
                marginLeft: 10,
              }}
            >
              {timestamp}
            </div>
          </div>
        );
      } else {
        return (
          <div className="messageContainer justifyEnd">
            <div className="messageBox backgroundBlue">
              <p className="messageText colorWhite">
                {ReactEmoji.emojify(text.originMessage)}
              </p>
            </div>
            <div
              style={{
                marginTop: 10,
                marginRight: 10,
              }}
            >
              {timestamp}
            </div>
          </div>
        );
      }
    } else {
      if (name === "admin") {
        return (
          <>
            <div className="messageContainer justifyStart">
              <div className="messageBox backgroundLight">
                <div className="messageText colorDark">
                  {text.transLateLanguage && (
                    <p>번역문 : {ReactEmoji.emojify(text.transLateLanguage)}</p>
                  )}
                  <p>원문 : {ReactEmoji.emojify(text.originMessage)}</p>
                </div>
              </div>
              <div
                style={{
                  marginTop: 10,
                  marginLeft: 10,
                }}
              >
                {timestamp}
              </div>
              {/* <p className="sentText pl-10 ">{user}</p> */}
            </div>
          </>
        );
      } else {
        return (
          <div className="messageContainer justifyEnd">
            <div className="messageBox backgroundBlue">
              <p className="messageText colorWhite">
                {ReactEmoji.emojify(text.originMessage)}
              </p>
            </div>
            <div
              style={{
                marginTop: 10,
                marginRight: 10,
              }}
            >
              {timestamp}
            </div>
          </div>
        );
      }
    }
    return null;
  }
  return showText();
};

export default Message;
