import React, { useState, useEffect, useRef } from "react";
import io from "socket.io-client";

import Messages from "../Messages/Messages";
import InfoBar from "../InfoBar/InfoBar";
import Input from "../Input/Input";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { v4 as uuidv4 } from "uuid";
import { useLocation, useNavigate } from "react-router-dom";

import "./Chat.css";
import FirstMic from "../../icons/FirstMic.png";
import FirstClickMic from "../../icons/FirstClickMic.png";

const ENDPOINT = process.env.REACT_APP_END_POINT;

let socket;

const Chat = () => {
  const [name, setName] = useState("");
  const [room, setRoom] = useState("");
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [userLanguage, setUserLanguage] = useState();
  // const [languageModal, setLanguageModal] = useState(false);
  // const [userClickLanguage, setUserClickLanguage] = useState("");
  const [isLisning, setIsLisning] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [nickNameModal, setNickNameModal] = useState(false);
  const [userInputName, setUserInputName] = useState("");
  const [isFirstMic, setIsFirstMic] = useState(true);
  const [isLisningFirst, setIsLisningFirst] = useState(false);
  const [userName, setUserName] = useState("");
  const [exitModal, setExitModal] = useState(false);
  const [clickMic, setClickMic] = useState(false);
  const [userExit, setUserExit] = useState(false);
  const [isMicLisning, setIsMicLisning] = useState(false);
  const [sendUserText, setSendUserText] = useState(false);
  const scrollBottomRef = useRef();
  const USER_LANGUAGE = {
    Korean: "ko",
    English: "en",
    Chinese: "cn",
    Taiwanese: "tw",
    Japanese: "jp",
    Thai: "th",
  };
  const location = useLocation();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(location.search);
  const { transcript, resetTranscript } = useSpeechRecognition();

  const fetchChatHistory = async (room) => {
    const response = await fetch(`${ENDPOINT}chatHistory/${room}`);
    const data = await response.json();
    return data;
  };

  const readMessage = async (room) => {
    await fetch(`${ENDPOINT}readMessage/${room}`);
  };
  window.addEventListener("resize", () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  });
  useEffect(() => {
    if (localStorage.getItem("userInfo") !== null) {
      SpeechRecognition.startListening();
      setTimeout(() => {
        SpeechRecognition.stopListening();
      }, 100);
    }
  }, []);

  const previousUser = async (userInfo) => {
    const user = JSON.parse(userInfo);
    const response = await fetch(
      `${process.env.REACT_APP_END_POINT}activeRoom/${user.uuid}`
    );

    const data = await response.json();
    if (data.length !== 0 && data[0].isChattingActive) {
      fetchChatHistory(user.uuid).then((res) => {
        setMessages(res);
      });
      socket.emit(
        "join",
        { name: user.userName, room: user.uuid, location },
        (error) => {}
      );
    } else {
      localStorage.removeItem("userInfo");
      window.location.reload();
    }
  };
  useEffect(() => {
    socket = io(ENDPOINT);
    const name = searchParams.get("name");
    const room = searchParams.get("room");
    const location = searchParams.get("location");
    if (name === "admin") {
      readMessage(room);
      setRoom(room);
      setName(name);
      fetchChatHistory(room).then((res) => {
        setMessages(res);
        if (name === "admin") {
          setUserLanguage(res[res.length - 1].userLanguage);
        }
      });
      socket.emit("join", { name: "admin", room: room }, (error) => {});

      return;
    }
    const userInfo = localStorage.getItem("userInfo");
    if (userInfo === null) {
      setNickNameModal(true);
    } else {
      previousUser(userInfo);
    }

    if (name !== "admin") {
      setUserLanguage(navigator.language);
    }
  }, []);

  useEffect(() => {
    socket.on("message", (message) => {
      setMessages((messages) => [...messages, message]);
      setIsLoading(false);
      console.log(scrollBottomRef);
    });
    socket.on("exitChatting", () => {
      console.log("채팅 종료");
      setUserExit(true);
    });
    socket.on("roomData", ({ users }) => {});

    socket.on("sendSuccess", () => {
      setIsLoading(false);
      setMessage("");
    });
  }, []);

  const sendMessage = async () => {
    const room = searchParams.get("room");
    const getUserInfo = JSON.parse(localStorage.getItem("userInfo"));
    const getAdminInfo = JSON.parse(localStorage.getItem("adminInfo"));

    if (transcript === "") return;
    setIsLoading(true);

    if (transcript) {
      socket.emit(
        "sendMessage",
        {
          roomNumber: room !== null ? room : getUserInfo.uuid,
          userName:
            room !== null
              ? "admin " + getAdminInfo.adminName
              : getUserInfo.uuid,
          origin: transcript,
          userLanguage,
        },
        () => {}
      );
      return;
    }

    resetTranscript();
  };

  const sendMessageWithType = async () => {
    const room = searchParams.get("room");
    const getUserInfo = JSON.parse(localStorage.getItem("userInfo"));
    const getAdminInfo = JSON.parse(localStorage.getItem("adminInfo"));

    setIsLoading(true);

    if (message) {
      socket.emit(
        "sendMessage",
        {
          roomNumber: room !== null ? room : getUserInfo.uuid,
          userName:
            room !== null
              ? "admin " + getAdminInfo.adminName
              : getUserInfo.uuid,
          origin: message,
          userLanguage,
        },
        () => {}
      );
    }
  };

  const startListening = (e) => {
    setIsLisning(true);
    SpeechRecognition.startListening({ continuous: true });
  };
  const startListeningFirst = (e) => {
    SpeechRecognition.startListening({ continuous: true });
  };

  useEffect(() => {
    if (sendUserText) {
      sendMessage();
      setSendUserText(false);
    }
  }, [sendUserText]);

  useEffect(() => {
    const handleMouseDown = () => {
      if (isLisningFirst) {
        startListeningFirst();
      }
    };

    const handleMouseUp = () => {
      if (isLisningFirst) {
        setTimeout(() => {
          setSendUserText(true);
          SpeechRecognition.abortListening();
          resetTranscript();
        }, 1000);

        setIsLisningFirst(false);
        setIsFirstMic(false);
        setClickMic(false);
        setIsMicLisning(false);
      }
    };

    window.addEventListener("pointerdown", handleMouseDown);
    window.addEventListener("pointerup", handleMouseUp);

    return () => {
      window.removeEventListener("pointerdown", handleMouseDown);
      window.removeEventListener("pointerup", handleMouseUp);
    };
  }, [isLisningFirst]);

  useEffect(() => {
    if (isLisningFirst || isMicLisning) {
      startListeningFirst();
      return;
    }
    if (clickMic) {
      setIsLisningFirst(false);
      setIsFirstMic(false);
      setClickMic(false);
      setIsMicLisning(false);
    }
  }, [isLisningFirst, clickMic, isMicLisning]);

  const speechStart = () => {
    SpeechRecognition.startListening();
    setTimeout(() => {
      SpeechRecognition.stopListening();
    }, 100);
  };

  if (userExit) {
    return (
      <div
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          whiteSpace: "pre-line",
        }}
      >
        <div
          style={{
            textAlign: "center",
            fontSize: 25,
          }}
        >{`채팅이 종료되었습니다.\n 감사합니다.`}</div>
      </div>
    );
  }

  return (
    <div className="outerContainer">
      {
        <div className="container">
          <InfoBar
            userLanguage={userLanguage}
            room={room}
            userName={userName}
            setUserName={setUserName}
            setExitModal={setExitModal}
            nickNameModal={nickNameModal}
          />
          <Messages
            messages={messages}
            name={name}
            userLanguage={userLanguage}
            room={room}
            scrollBottomRef={scrollBottomRef}
          />
          {nickNameModal ? (
            <> </>
          ) : (
            <>
              {isFirstMic && searchParams.get("room") === null ? (
                <div
                  style={{
                    flexDirection: "column",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {!isLisningFirst && (
                    <>
                      <div
                        style={{
                          fontWeight: 400,
                          fontSize: 14,
                          color: "#000000",
                          marginBottom: 15,
                        }}
                      >
                        Press button and say
                      </div>
                      <img
                        style={{
                          marginBottom: 30,
                        }}
                        width={50}
                        height={50}
                        src={FirstMic}
                        onPointerDown={(e) => {
                          e.preventDefault();
                          setClickMic(true);
                          setIsLisningFirst(true);
                        }}
                      ></img>
                    </>
                  )}
                </div>
              ) : (
                <>
                  <Input
                    startListening={startListening}
                    isLoading={isLoading}
                    message={message}
                    setMessage={setMessage}
                    sendMessage={sendMessage}
                    setIsLisning={setIsLisning}
                    isLisning={isLisning}
                    setIsFirstMic={setIsFirstMic}
                    setIsLisningFirst={setIsLisningFirst}
                    setIsMicLisning={setIsMicLisning}
                    setClickMic={setClickMic}
                    isMicLisning={isMicLisning}
                    setSendUserText={setSendUserText}
                    sendUserText={sendUserText}
                    resetTranscript={resetTranscript}
                    sendMessageWithType={sendMessageWithType}
                  />
                </>
              )}
            </>
          )}
        </div>
      }
      {(isLisning || exitModal) && <div className="overlay"></div>}

      {isLisning && (
        <div className="modal">
          <div
            style={{
              fontSize: "20px",
              marginBottom: "20px",
              marginTop: "10px",
            }}
          >
            <div className="dot-container">
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
            </div>
          </div>
          <div
            onClick={() => {
              SpeechRecognition.stopListening();
              setMessage(transcript);
              setIsLisning(false);
            }}
            style={{
              backgroundColor: "#FFC028",
              width: "100%",
              padding: "10px",
              color: "white",
              borderRadius: "5px",
            }}
          >
            Stop
          </div>
        </div>
      )}

      {exitModal && (
        <div className="modal">
          <div
            style={{
              fontSize: "20px",
              marginBottom: "20px",
              marginTop: "10px",
            }}
          >
            채팅을 종료하시겠습니까?
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div
              onClick={() => {
                setExitModal(false);
              }}
              style={{
                width: 100,
                marginRight: 20,
                padding: "10px",
                color: "#FFC028",
                borderRadius: "5px",
                border: "1px solid #FFC028",
              }}
            >
              취소
            </div>
            <div
              onClick={() => {
                const room = searchParams.get("room");
                const userInfo = JSON.parse(localStorage.getItem("userInfo"));
                if (room !== null) {
                  fetch(`${ENDPOINT}exitChatting/${room}`);
                } else {
                  fetch(`${ENDPOINT}exitChatting/${userInfo.uuid}`);
                }

                setTimeout(() => {
                  if (room === null) {
                    localStorage.removeItem("userInfo");
                    setUserExit(true);
                  } else {
                    setExitModal(false);
                    navigate(-1);
                  }
                }, 500);
              }}
              style={{
                backgroundColor: "#FFC028",
                width: 100,

                padding: "10px",
                color: "white",
                borderRadius: "5px",
              }}
            >
              종료
            </div>
          </div>
        </div>
      )}

      {isLisningFirst && !isMicLisning && (
        <div className="overlayFirst">
          <div
            className="overlayButton"
            style={{
              display: "flex",
              flexDirection: "column-reverse",
              alignItems: "center",
              justifyItems: "flex-start",
              bottom: 0,
            }}
          >
            <img
              style={{
                marginBottom: 30,
                bottom: 0,
              }}
              width={50}
              height={50}
              src={FirstClickMic}
              onMouseLeave={() => {
                SpeechRecognition.stopListening();
                setMessage(transcript);
                setIsLisningFirst(false);
                setIsFirstMic(false);
                setIsMicLisning(false);
              }}
            ></img>
            <div
              style={{
                bottom: 0,
                zIndex: 10,
                fontWeight: 400,
                fontSize: 14,
                color: "#FFFFFF",
                marginBottom: 15,
              }}
            >
              Release the button after you finish speaking
            </div>
          </div>
        </div>
      )}

      {isMicLisning && (
        <div className="overlayButtonFirst">
          <div
            className="overlayMicButton"
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ position: "relative", color: "white", bottom: 50 }}>
              Please Speaking
            </div>
          </div>
        </div>
      )}

      {(isLisningFirst || isMicLisning) && (
        <>
          <div className="firstmodal">
            <div className="dot-container">
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
            </div>
          </div>
        </>
      )}

      {nickNameModal && (
        <div
          style={{
            position: "absolute",
            borderRadius: "10px",
            padding: "20px 0px 30px 0px",
            backgroundColor: "white",
            alignItems: "center",
            display: "flex",
            textAlign: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              fontSize: "14px",
              marginBottom: "20px",
              marginTop: "10px",
            }}
          >
            Please write down your name
          </div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "20px 10px",
              padding: "0px 16px",
            }}
          >
            <div
              style={{
                width: "100%",
                borderColor: "",
                borderRadius: "5px",
                border: `1px solid ${
                  userInputName === "" ? "#A2A2A2" : "#FFC028"
                }`,
              }}
            >
              <input
                style={{
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  borderColor: "#FFC028",
                  border: "none",
                  width: "100%",
                }}
                value={userInputName}
                onChange={(event) => {
                  setUserInputName(event.target.value);
                }}
              />
            </div>
            {userInputName !== "" ? (
              <div
                onClick={() => {
                  const newUuid = uuidv4();
                  const location = searchParams.get("location");
                  localStorage.setItem(
                    "userInfo",
                    JSON.stringify({
                      uuid: newUuid,
                      userName: userInputName,
                      visitTime: new Date(),
                      location,
                    })
                  );
                  setUserName(userInputName);
                  socket.emit(
                    "join",
                    { name: userInputName, room: newUuid, location },
                    (error) => {}
                  );

                  setName(userInputName);
                  setNickNameModal(false);
                  speechStart();
                }}
                style={{
                  backgroundColor: "#FFC028",
                  width: "100%",
                  padding: "10px",
                  color: "white",
                  borderRadius: "5px",
                }}
              >
                Enter
              </div>
            ) : (
              <div
                style={{
                  backgroundColor: "#DEDEDE",
                  width: "100%",
                  padding: "10px",
                  color: "white",
                  borderRadius: "5px",
                }}
              >
                Enter
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Chat;
