import React from "react";

import ScrollToBottom from "react-scroll-to-bottom";

import Message from "./Message/Message";

import "./Messages.css";

const Messages = ({ messages, userLanguage, room, scrollBottomRef }) => {
  return (
    <ScrollToBottom className="messages">
      {messages.map((message, i) => {
        return (
          <div key={i}>
            <Message
              userLanguage={userLanguage}
              message={message}
              room={room}
            />
          </div>
        );
      })}
    </ScrollToBottom>
  );
};

export default Messages;
