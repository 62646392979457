import React, { useEffect, useState } from "react";
import "./ChattingList.css";
import { Link, useNavigate } from "react-router-dom";
import io from "socket.io-client";

const ENDPOINT = process.env.REACT_APP_END_POINT;
const ADMIN_NAME = ["홍주완", "고석수", "이종선", "이종훈", "이종선1"];
const TOTAL_MEMBER = [
  "이종선",
  "고석수",
  "강명수",
  "이종훈",
  "이유나",
  "박민호",
  "이선재",
  "이승화",
  "장수빈",
  "홍주완",
];
export default function ChattingList() {
  let socket;
  socket = io(ENDPOINT);
  const navigate = useNavigate();
  const [roomList, setRoomList] = useState([]);
  const [userLogin, setIsUserLogin] = useState(false);
  const [adminName, setAdminName] = useState("");
  const messageList = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_END_POINT}activeRooms`
    );
    const data = await response.json();
    const result = data.filter((v) => v.chattingHistory !== undefined);
    const resultActive = result.filter((v) => v.isChattingActive);
    const resultDeactive = result.filter((v) => !v.isChattingActive);
    setRoomList([...resultActive, ...resultDeactive]);
  };
  useEffect(() => {
    const handleMessage = (event) => {
      // 보안을 위해 메시지 출처를 확인
      setIsUserLogin(true);
      const date = new Date();

      localStorage.setItem(
        "adminInfo",
        JSON.stringify({
          userLogin: true,
          loginTime: date,
          adminName: event.data,
        })
      );
      messageList();
      // 여기서 받은 메시지를 처리합니다
    };

    window.addEventListener("message", handleMessage);

    // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
    return () => window.removeEventListener("message", handleMessage);
  }, []);

  useEffect(() => {
    const isUserLogin = JSON.parse(localStorage.getItem("adminInfo"));
    if (isUserLogin === null) {
      setIsUserLogin(false);
      // 데브 버전
      // setIsUserLogin(true);
      // const date = new Date();

      // localStorage.setItem(
      //   "adminInfo",
      //   JSON.stringify({
      //     userLogin: true,
      //     loginTime: date,
      //     adminName: "홍주완",
      //   })
      // );

      // messageList();
    } else {
      setIsUserLogin(true);

      const date = new Date();

      localStorage.setItem(
        "adminInfo",
        JSON.stringify({
          userLogin: true,
          loginTime: date,
          adminName: isUserLogin.adminName,
        })
      );
      setAdminName(isUserLogin.adminName);
      messageList();
    }
  }, []);

  useEffect(() => {
    socket.on("updateNewMessage", () => {
      messageList();
    });
  }, []);

  const changeName = (name) => {
    if (name === "이종선" || name === "이종선1") {
      return "대표님";
    }
    if (name === "고석수") {
      return "CPO님";
    }
    if (name === "이종훈") {
      return "본부장님";
    }
    if (name === "강명수") {
      return "리드매니저님";
    }
    return "매니저님";
  };

  const changeManager = async (item, newManager) => {
    fetch(`${process.env.REACT_APP_END_POINT}changeManager`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        room: item.roomId,
        newManager,
      }),
    }).then(() => {
      alert(
        "담당자가 " + newManager + changeName(newManager) + "로 지정되었습니다."
      );
      window.location.reload();
    });
  };

  return userLogin ? (
    <div className="paddingDiv">
      <div className="chatjoinOuterContainer">
        <div className="joinInnerContainer">
          <div className="heading">
            채팅 리스트
            {userLogin && (
              <button
                style={{
                  border: "none",
                  color: "white",
                  borderRadius: "10px",
                  backgroundColor: "#FFC028",
                  width: "10%",
                  height: 30,
                  position: "absolute",
                  right: "5%",
                  top: "2%",
                }}
                onClick={() => {
                  window.localStorage.removeItem("adminInfo");
                  setIsUserLogin(false);
                }}
              >
                로그아웃
              </button>
            )}
          </div>
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              border: "1px solid #ddd",
            }}
          >
            <thead>
              <tr
                style={{
                  color: "white",
                  border: "1px solid #ddd",
                }}
              >
                {[
                  "언어",
                  "지점",
                  "사용자",
                  "메시지",
                  "메시지 수신시간",
                  "메시지 발송 시간",
                  "채팅 종료 시간",
                  "담당자",
                  "담당자가 안 읽은 메시지",
                ].map((v) => {
                  return <th className="tdStyle">{v}</th>;
                })}
                {ADMIN_NAME.includes(adminName) ? (
                  <th className="tdStyle">담당자 교체</th>
                ) : (
                  <th className="tdStyle">담당자 교체</th>
                )}
              </tr>
            </thead>
            <tbody>
              {roomList.map((item) => {
                return (
                  <tr
                    style={{
                      color: item.isChattingActive ? "white" : "#d2d2d2",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      navigate(`/chat?name=admin&room=${item.roomId}`, {
                        state: { data: item.manager },
                      });
                    }}
                  >
                    <td className="tdStyle">{item.userLanguage}</td>
                    <td className="tdStyle">{item.location}</td>
                    <td className="tdStyle">{item.userName}</td>
                    <td className="tdStyle">{item.chattingHistory}</td>
                    <td className="tdStyle">
                      {item.userLastSend.length === 0
                        ? ""
                        : item.userLastSend[item.userLastSend.length - 1]
                            .timestamp}
                    </td>

                    <td className="tdStyle">
                      {item.adminLastSend.length === 0
                        ? ""
                        : item.adminLastSend[item.adminLastSend.length - 1]
                            .timestamp}
                    </td>
                    <td className="tdStyle">{item.chattingCloseTime}</td>
                    <td className="tdStyle">
                      {item.manager === ""
                        ? "미배정"
                        : item.manager + " " + changeName(item.manager)}
                    </td>
                    <td
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      className="tdStyle"
                    >
                      {item.noReadMessage === 0 ? (
                        <span>없음</span>
                      ) : (
                        <span
                          style={{
                            textAlign: "center",
                            backgroundColor: "red",
                            padding: "2px 5px",
                            borderRadius: "22px",
                          }}
                        >
                          {item.noReadMessage}
                        </span>
                      )}
                    </td>
                    {ADMIN_NAME.includes(adminName) && item.isChattingActive ? (
                      <td
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        className="tdStyle"
                      >
                        <select
                          name="languages"
                          id="lang"
                          defaultValue={
                            item.manager === "" ? "미배정" : item.manager
                          }
                          onChange={(e) => {
                            changeManager(item, e.target.value);
                          }}
                        >
                          <option value="">미배정</option>
                          {TOTAL_MEMBER.map((v) => {
                            if (v === "이종선") {
                              return <option value={v}>{v} 대표님</option>;
                            }
                            if (v === "고석수") {
                              return <option value={v}>{v} CPO님</option>;
                            }
                            if (v === "이종훈") {
                              return <option value={v}>{v} 본부장님</option>;
                            }
                            if (v === "강명수") {
                              return (
                                <option value={v}>{v} 리드매니저님</option>
                              );
                            }
                            return <option value={v}>{v} 매니저</option>;
                          })}
                        </select>
                      </td>
                    ) : (
                      <td
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        className="tdStyle"
                      ></td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  ) : (
    <div className="paddingDiv">
      <div className="managerLogin">
        <h1 className="heading">관리자 로그인</h1>
        <div>
          <button
            className="googleLogin"
            onClick={() => {
              window.open(
                "https://dev.moneyplex.kr/_plugin/social/popup2.php?provider=google",
                "_blank",
                "width=500, height=500"
              );
            }}
          >
            구글 로그인
          </button>
        </div>
      </div>
    </div>
  );
}
